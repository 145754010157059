<template>
    <div class="socialReponse">
         <div class="swiper-container pageTop_box" id="socialTop_swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,i) in banner" :key="i">
                    <div class="bg_img" :style="'background-image: url('+item+')'"></div>
                </div>
            </div>
            <div class="pagination socialTop-pagination"></div>
        </div>

        <div class="box_ socialReponse_1_box">
            <div class="content_">
                <div v-for="(item,index) in social" :key="item.id" :class="setClass(index)">
                    <div class="img_box"><div :style="'background-image: url('+item.thumbnail+')'"></div></div>
                    <div class="right_">
                        <div class="name">{{item.title}}</div>
                        <div class="line"></div>
                        <div class="info">{{item.excerpt || item.content|edit_content}}</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="socialReponse_2_box" ref="numberBox" :style="'background-image: url('+publicWelfare.img+')'">
            <div class="box_ content_">
                <div class="title">{{publicWelfare.name||'易宝公益'}}</div>
                <div class="line"></div>
                <div class="info">{{publicWelfare.title}}</div>
                <div class="number_" ref="number_1">{{publicWelfare.number}}</div>
                <div class="desc">{{publicWelfare.desc}}</div>
            </div>
        </div>

        <div class="box_ line_two_box">
            <div >
                <!-- 平台简介 -->
                <div class="socialReponse_3_box">
                    <div class="socialReponse_title_box">
                        <div class="lineLeft_box_title">{{introductionDetail.name||'平台简介'}}</div>
                    </div>
                    <div class="info" v-html="introductionDetail.content"></div>
                    <div class="video_box" v-if="introductionDetail.video">
                        <video :src="introductionDetail.video" controls></video>
                    </div>
                </div>
                
                <!-- 慈善募捐 -->
                <div class="socialReponse_4_box">
                    <div class="socialReponse_title_box">
                        <div class="lineLeft_box_title">{{raiseMoney.name||'慈善募捐'}}</div>
                        <button class="than_more" @click="openRaise()">查看更多></button>
                    </div>
                    <div class="scrollbar_box list_box">
                        <div v-for="item in raiseMoney.list" :key="item.id" @click="goRaiseDetail(item.source)">
                            <div class="img_box"><div :style="'background-image: url('+item.thumbnail+')'"></div></div>
                            <div class="ellipsis title">
                                <img :src="item.icon" >
                                <span>{{item.title}}</span>
                            </div>
                            <div class="btn_">
                                <span class="ellipsis">{{item.keywords}}</span>
                                <button v-if="item.source">慈善募捐</button>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>

        <!-- 发展历程 -->
        <div class="box_ socialReponse_5_box">
            <div class="lineLeft_box_title">{{development_name||'发展历程'}}</div>

            <div class="content_" :class="developClass">
                <div class="list_tab bot">
                    <div :class="item.title!=null?'':'hidden'" v-for="item in developList1" :key="item.id">
                        <div class="box_dt">
                            <div class="bg_color">
                                <div class="name">{{item.title}}</div>
                                <div class="info">{{item.content|edit_content}}</div>
                            </div>
                            <div class="sj"></div>
                        </div>
                        <div class="dot"></div>
                    </div>
                </div>

                <div class="arrow_line">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <div class="list_tab top">
                    <div v-for="item in developList2" :key="item.id">
                        <div class="dot"></div>
                        <div class="box_dt">
                            <div class="sj"></div>
                            <div class="bg_color">
                                <div class="name">{{item.title}}</div>
                                <div class="info">{{item.content|edit_content}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <!-- 公益剪影 -->
        <div class="box_ socialReponse_6_box">
            <div class="socialReponse_title_box">
                <div class="lineLeft_box_title">{{articles_data.name||'公益剪影'}}</div>
                <button class="than_more"  @click="onGetMore(articles_data.name, articles_data.category_id)">查看更多></button>
            </div>
            <div class="info_list" >
                <div v-for="item in articles_data.list" :key="item.id" @click="onInfoDetail(item.id)">
                    <div class="img_box"><div  :style="'background-image: url('+item.thumbnail+')'"></div></div>
                    <div class="info">{{item.title}}</div>
                </div>
            </div>
        </div>

        <footerComponent style="margin-top:50px"></footerComponent>
    </div>
</template>
<script>
import {timedCountFunc} from "@/util/index"
export default {
    name:'socialReponse',
    data(){
        return{
            numberDivTop: null,
            developList1:[],
            developList2:[],
            developClass: null,

            banner:[],
            social:[],
            publicWelfare:{},
            introductionDetail:{},
            development_name:'',
            articles_data:{
                list:[]
            },
            raiseMoney:{
                list:[]
            },
            
            prefixImg:'',
        }
    },
    computed:{
        scrollTop:{
            get(){
                return this.$store.state.pageScrollTop
            },
        },
    },
    watch:{
        scrollTop:{
            handler(val){
                if(this.publicWelfare.number && this.numberDivTop && val >= this.numberDivTop){
                    this.numberDivTop = null
                    timedCountFunc(this.$refs['number_1'], 0, this.publicWelfare.number, 1, true, 500)
                }
            }
        }
    },
    created(){
        this.initData()
    },
    methods:{
        initData(){
            this.$Request({
                url: '/portal/social_responsibility',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                this.prefixImg = data.url+'/';
                //banner
                this.banner = this.$getListByObj(data.banner).map(item=>{return item.image});
                if(this.banner.length>1){
                    this.$nextTick(()=>{
                        new Swiper ('#socialTop_swiper', {
                            pagination: '.socialTop-pagination',
                            paginationClickable :true,
                            autoplay: 5000,
                        })
                    })
                }
                //社会责任
                let social_responsibility= this.$initNes(data.social_responsibility||{})
                this.social = social_responsibility.val;
                //易宝公益
                let public_welfare = this.$initNes(data.public_welfare||{}),
                    public_detail = public_welfare.val[0];
                this.$set(this,'publicWelfare',{
                    name: public_welfare.name,
                    title: public_detail.title,
                    desc: public_detail.excerpt,
                    number: Number(public_detail.keywords.replace(/,/gi,'')),
                    img: public_detail.thumbnail
                })
                this.$nextTick(()=>{
                    let el = this.$refs.numberBox;
                    this.numberDivTop = el.offsetTop - el.clientHeight;
                })
                //平台简介
                let introduction_platform = this.$initNes(data.introduction_platform||{}),
                    introduction_detail = introduction_platform.val[0];
                this.$set(this,'introductionDetail',{
                    name: introduction_platform.name,
                    content: introduction_detail.content,
                    video: introduction_detail.video,
                })  
                //慈善募捐
                let raise_money_charity = this.$initNes(data.raise_money_charity||{});
                this.$set(this,'raiseMoney',{
                    name: raise_money_charity.name,
                    list: raise_money_charity.val.map(item=>{
                        item.icon = this.prefixImg + '' + (item.img[0]&&item.img[0].url);
                        return item
                    }),
                })
                //发展历程
                let development_course = this.$initNes(data.development_course||{});
                this.development_name = development_course.name;
                this.setDevelopList(development_course.val)
                //公益剪影
                let articles = this.$initNes(data.articles||{},'category_id')
                this.$set(this,'articles_data',{
                    name: articles.name,
                    list: articles.val,
                    category_id: articles.category_id,
                })
                
            })
        },
        setDevelopList(list){
            if(list.length>7){
                list = list.splice(0,7)
            }
            //最多7个
            list.forEach((item, index)=>{
                if(index%2 == 0){
                    this.developList1.push(item)
                }else{
                    this.developList2.push(item)
                }
            })
            if(list.length<6 && this.developList1.length== this.developList2.length){
                this.developList1.push({title:null,id:-1,})
            }
            this.developClass = (list.length>6)?'cl_':'cl'+list.length;
        },
        setClass(i){
           let r = parseInt(i/2);
           return r%2==0?'odd':'even'
        },
        onInfoDetail(id){
            this.$router.push({name:'infomationDetail', query:{id: id}})
        },
        onGetMore(name, id){
            this.$router.push({name:'infomationList', query:{name: name, id: id}})
        },
        openRaise(){
            window.open("https://gongyi.yeepay.com/www/charitable.html")
        },
        goRaiseDetail(url){
            window.open(url)
        }
    }
}
</script>
<style lang="scss" scoped>
.socialReponse{
    .pageTop_box{
        width: 100%;
        height: 450px;
    }
    .socialReponse_title_box{
        text-align:left;
        padding-bottom: 20px;
        border-bottom: 1px solid #666666;
        position: relative;
        .than_more{
            position: absolute;
            right: 0;
            bottom: 19px;
        }
    }
    .box_{
        width: 1200px;
        margin: 0 auto;
    }
    .socialReponse_1_box{
        margin-top: 60px;
        .content_{
            display: inline-block;
            width: 100%;
            >div{
                width: 588px;
                height: 282px;
                background: #FFFFFF;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                margin-bottom: 20px;
                position: relative;

                &::before{
                    content: '';
                    display: inline-block;
                    height: 100%;
                    width: 1px;
                    vertical-align: middle;
                }
                
                .img_box{
                    position: absolute;
                    top: 0;
                    width: 281px;
                    height: 100%;
                    overflow: hidden;
                    >div{
                        width: 100%;
                        height: 100%;
                        @include backImg('../assets/img/home_6_1.png');
                        background-color: #ccc;
                        transition: all .3s ease;
                    }
                }
                .right_{
                    padding: 0 20px;
                    display: inline-block;
                    vertical-align: middle;
                    width: calc(100% - 285px);
                    text-align: left;
                    .name{
                        font-size: 24px;
                        color: #333333;
                    }
                    .line{
                        width: 100px;
                        height: 2px;
                        background-color: $lv;
                        margin-top: 15px;
                    }
                    .info{
                        margin-top: 20px;
                        font-size: 14px;
                        color: #333333;
                        line-height: 21px;
                        @include textOver(8)
                    }
                }
                &:nth-child(2n){
                    float: right;
                }
                &:nth-child(2n-1){
                    float: left;
                }
                &.odd{
                    text-align: right;
                    // padding-left: 281px;
                    .img_box{
                        left: 0;
                    }
                }
                &.even{
                    text-align: left;
                    .img_box{
                        right: 0;
                    }
                }
                &:hover{
                   .img_box>div{
                       transform: scale(1.2);
                   } 
                }
            }
        }
    }
    .socialReponse_2_box{
        margin-top: 40px;
        position: relative;
        z-index: 0;
        width: 100%;
        min-height: 402px;
        @include backImg('../assets/img/home_6_1.png');
        .content_{
            padding-top: 75px;
            padding-bottom: 40px;
            text-align: center;
        }
        .title{
            font-size: 36px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 58px;
        }
        .line{
            margin: 0 auto;
            width: 86px;
            height: 4px;
            background-color: $lv;
        }
        .info{
            margin-top: 15px;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 21px;
        }
        .number_{
            margin: 34px auto 0;
            width: 552px;
            height: 90px;
            line-height: 90px;
            background-color: rgba($color: #fff, $alpha: 0.2);
            border-radius: 45px;
            text-align: center;
            font-size: 36px;
            font-weight: bold;
            color: #FFFFFF;
        }
        .desc{
            font-size: 14px;
            color: #FFFFFF;
            line-height: 21px;
            margin-top: 5px;
        }
    }
    .line_two_box{
        margin-top: 60px;
       >div{
           display: inline-block;
           width: 100%;
           >div{
               width: 588px;
               &:first-of-type{
                   float: left;
               }
               &:last-of-type{
                   float: right;
               }
           }
       }
    }
    .socialReponse_3_box{
        .info{
            margin-top: 20px;
            font-size: 14px;
            color: #333333;
            line-height: 25px;
        }
        .video_box{
            margin-top: 20px;
            width: 100%;
            height: 328px;
            video{
                width: 100%;
                height: 100%;
            }
        }
    }
    .socialReponse_4_box{
        .list_box{
            margin-top: 20px;
            display: inline-block;
            width: 100%;
            height: 560px;
            overflow-y: auto;
            padding: 0 4px;
            >div{
                width: 282px;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                padding-bottom: 14px;
                margin-bottom: 20px;
                cursor: pointer;
                &:nth-child(2n){
                    float: right;
                }
                &:nth-child(2n-1){
                    float: left;
                }
                .img_box{
                    width: 100%;
                    height: 158px;
                    overflow: hidden;
                    &>div{
                        width: 100%;
                        height: 100%;
                        @include backImg('../assets/img/home_6_1.png');
                        transition: all .2s ease;
                        background-color: #ccc;
                    }
                }
                .title{
                    padding: 10px 0 0 20px;
                    color: #333333;
                    >img{
                        width: 36px;
                        height: 36px;
                        vertical-align: middle;
                        margin-right: 10px;
                    }
                    >span{
                        font-size: 18px;
                        line-height: 21px;
                        vertical-align: middle;
                    }
                }
                .btn_{
                    padding: 5px 20px 0 20px;
                    >span{
                        font-size: 14px;
                        color: #333;
                        display: inline-block;
                        width: calc(100% - 72px);
                        vertical-align: middle;
                    }
                    >button{
                        vertical-align: middle;
                        width: 72px;
                        height: 36px;
                        line-height: 36px;
                        background: $lv;
                        border-radius: 8px;
                        text-align: center;
                        font-size: 14px;
                        color: #fff;
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                }
                &:hover{
                    .img_box>div{
                        transform: scale(1.2);
                    }
                    .title{
                        color: $lv;
                    }
                }
            }
        }
    }
    .socialReponse_5_box{
        margin-top: 20px;
        .content_{
            margin-top: 25px;
        }
        .list_tab{
            text-align: center;
            >div{
                display: inline-block;
                width: 282px;
                height: 167px;
                color: #333;
                position: relative;
                z-index: 0;
                cursor: pointer;
                &.hidden{opacity: 0;cursor: text;}
                .box_dt{
                    transition: all 0.3s ease;
                    position: absolute;
                    left: 0;
                    right: 0;
                }
                .bg_color{
                    padding: 16px 15px 0;
                    width: 100%;
                    height: 155px;
                    background-color: #E6E6E6;
                    z-index: -1;
                }
                .name{
                    text-align: left;
                    font-size: 24px;
                }
                .info{
                    text-align: left;
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 21px;
                    @include textOver(4)
                }
                .sj{
                    width: 0;
                    height: 0;
                    border: 12px solid;
                    margin: 0 auto;
                }
                .dot{
                    position: absolute;
                    left: 0;right: 0;
                    width: 30px;
                    height: 20px;
                    background-color: #fff;
                    margin: 0 auto;
                    z-index: 1;
                    &::before{
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 5px;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: #E6E6E6;
                        z-index: 9;
                    }
                }
                &:hover{
                    color: #fff;
                    .bg_color{
                        background-color: $lv;
                    }
                    .dot::before{background-color: $lv;}
                }
            }
            &.bot{
                margin-bottom: 8px;
                >div{
                    .box_dt{top: 0;}
                    .sj{border-color: #E6E6E6 transparent transparent transparent;}
                    .dot{bottom: -30px;}
                    &:hover{
                        .sj{
                            border-top-color: $lv;
                        }
                        .box_dt{transform: translateY(-15px);}
                    }
                } 
            }
            &.top{
                margin-top: 8px;
                >div{
                    .box_dt{bottom: 0;}
                    .sj{border-color: transparent transparent#E6E6E6 transparent;}
                    .dot{top: -30px;}
                    &:hover{
                        .sj{
                            border-bottom-color: $lv;
                        }
                        .box_dt{transform: translateY(15px);}
                    }
                }
            }
        }
        .arrow_line{
            // margin: 5px 0;
            height: 20px;
            >span{
                display: inline-block;
                vertical-align: middle;
               &:nth-child(1){
                   width: 24px;
                   height: 1px;
                   border-top: 4px dotted #E6E6E6;
               }
               &:nth-child(2){
                   width: calc(100% - 24px - 18px);
                   height: 4px;
                   background-color: #E6E6E6;
               }
               &:nth-child(3){
                    width: 0;
                    height: 0;
                    border: 9px solid;
                    border-color: transparent transparent transparent #E6E6E6;
               }
            }

        }
        .content_{
            &.cl_{
                .list_tab{
                   >div{
                       width: 240px;
                       .info{
                           @include textOver(3)
                       }
                   }
                    >div:not(:last-child){
                        margin-right: 75px;
                    } 
               }
            }
            &.cl6{
               .list_tab{
                    >div:not(:last-child){
                        margin-right: 75px;
                    } 
                    &.bot{
                        text-align: left;
                    }
                    &.top{
                        text-align: right;
                    }
               }
            }
            &.cl5{
                .list_tab>div:not(:last-child){
                    margin-right: 75px;
                }
            }
            &.cl4{
                .list_tab>div:not(:last-child){
                    margin-right: 75px;
                }
                .list_tab{
                    padding-left: 130px;
                }
            }
            &.cl3{
               .list_tab{
                   &.bot>div:not(:last-child){
                        margin-right: 200px;
                    }
               } 
            }
            &.cl2{
               .list_tab{
                    &.top{
                        margin-left: 300px;
                    }
                } 
            }
        }
    }
    .socialReponse_6_box{
        margin-top: 40px;
        .socialReponse_title_box{
            border-bottom-width: 0;
        }
         .info_list{
            display: inline-block;
            width: 100%;
            &>div{
                float: left;
                width: 385px;
                background: #cccccc;
                box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                margin-bottom: 20px;
                cursor: pointer;
                &:not(:nth-child(3n)){
                    margin-right: 18px;
                }
                .img_box{
                    width: 100%;
                    height: 218px;
                    overflow: hidden;
                    &>div{
                        width: 100%;
                        height: 100%;
                        @include backImg('../assets/img/home_6_1.png');
                        transition: all .3s ease;  
                    }
                }
                .info{
                    background-color: #fff;
                    padding: 15px 16px;
                    min-height: 80px;
                    font-size: 18px;
                    color: #333333;
                    line-height: 24px;
                    @include textOver(2)
                }
                &:hover{
                    .img_box>div{
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}
</style>